import React, { useEffect, useState } from 'react';

import { AxiosError, AxiosResponse } from 'axios';
import classNames from 'classnames';
import { trim } from 'lodash';
import { useMutation } from 'react-query';

import pxios from '../../apis/pxios';
import { haptic } from '../../cores/haptic';
import Button from '../../design-system/Button/Button';
import Typography from '../../design-system/Typography/Typography';
import { useToast } from '../../hooks/useToast';
import { HashIdAndToken } from '../../pages/Home';
import { getUUID } from '../../utils/getUUID';
import CarNumberInput from '../CarNumberInput/CarNumberInput';

import styles from './CarNumberForm.module.scss';

interface CreateCarInput {
  car_number: string;
  web_uuid: string;
}

interface CreateCarResponse {
  hash_id: string;
  token: string;
}

interface Props {
  onClickConfirmButton: () => void;
  onClose: () => void;
  setHashIdAndToken: React.Dispatch<React.SetStateAction<HashIdAndToken>>;
  section2InView: boolean;
}

const CarNumberForm: React.FC<Props> = ({ onClickConfirmButton, onClose, setHashIdAndToken, section2InView }) => {
  const toast = useToast();
  const [carNumber, setCarNumber] = useState<string | null>(null);

  const carMutation = useMutation<AxiosResponse<CreateCarResponse>, AxiosError, CreateCarInput>(
    (data) => pxios.post(`pre_inspection/cars/create/`, data),
    {
      onSuccess: (data) => {
        const hashId = data.data.hash_id;
        const token = data.data.token;
        localStorage.setItem('token', token);
        setHashIdAndToken({ hashId, token });
      },
      onError: () => {
        // 실패 시 팝업을 바로 닫는 동작이 어색하게 느껴져 700ms 뒤 닫게한다.
        setTimeout(() => {
          onClose();
        }, 700);
      },
    }
  );

  const onChangeCarNumber = (carNumber: string) => {
    setCarNumber(carNumber);
  };

  const onClick = () => {
    if (!carNumber) {
      toast.fail('차량번호를 입력해주세요.');
      return;
    }
    onClickConfirmButton();
    carMutation.mutate({ car_number: carNumber, web_uuid: getUUID() });
    haptic.interact();
  };

  return (
    <form className={classNames(styles.carNumberForm, { [styles.view]: section2InView })}>
      <div className={styles.carNumberInputWrapper}>
        <CarNumberInput onChange={onChangeCarNumber} />
        <Button variant="primary-brandcolor" text="결과보기" type="button" className={styles.button} onClick={onClick} />
      </div>
      <Typography variant="Subtitle_16">👆 내 차도 진단 가능한지 확인해볼까요?</Typography>
    </form>
  );
};

export default CarNumberForm;
