import { useQuery } from 'react-query';

import pxios from '../../apis/pxios';
import { Car } from '../../declaration/car';
import { setAuthorization } from '../../utils/setAuthorization';

export const useCar = (hashId: string | null, token: string | null) => {
  return useQuery(
    `pre_inspection/cars/${hashId}/`,
    () => pxios.get<Car>(`pre_inspection/cars/${hashId}/`, setAuthorization(token)).then((response) => response.data),
    {
      enabled: !!hashId,
    }
  );
};
