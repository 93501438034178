import React from 'react';

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import Toast from './components/Toast/Toast';
import Home from './pages/Home';
import Result from './pages/Result/Result';
import SubmitDone from './pages/SubmitDone/SubmitDone';

const Root: React.FC = () => {
  return (
    <Router>
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/cars/:hash_id/submit-done" exact component={SubmitDone} />
        <Route path="/cars/:hash_id/result" exact component={Result} />
      </Switch>
      <Toast />
    </Router>
  );
};

export default Root;
