import React from 'react';

import Emoji from '../../../component-system/Emoji/Emoji';
import { Car } from '../../../declaration/car';
import Typography from '../../../design-system/Typography/Typography';

import styles from './CarInfoSummary.module.scss';

interface Props {
  data: Car;
}

const CarInfoSummary: React.FC<Props> = ({ data }) => {
  return (
    <div className={styles.wrapper}>
      <Typography variant={'Subtitle_3'} className={styles.header}>
        {data.car_number}
      </Typography>
      <div className={styles.row}>
        <Typography variant={'Body_14'} className={styles.label}>
          <Emoji className={styles.emoji} name={'sport-utility-vehicle'} /> 모델명
        </Typography>
        <Typography variant={'Body_14'} className={styles.modalName}>
          {data.model_name_display}
        </Typography>
      </div>
      <div className={styles.row}>
        <Typography variant={'Body_14'} className={styles.label}>
          <Emoji className={styles.emoji} name={'two-o-clock'} /> 연식
        </Typography>
        <Typography variant={'Body_14'}>{data.year}</Typography>
      </div>
    </div>
  );
};

export default CarInfoSummary;
