import React, { Dispatch, SetStateAction, useState } from 'react';

import { AxiosError, AxiosResponse } from 'axios';
import classNames from 'classnames';
import { useFormik } from 'formik';
import { get, every, trim } from 'lodash';
import { useMutation, useQueryClient } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';

import AddressInput from 'components/AddressInput/AddressInput';
import { ReactComponent as ArrowDown } from 'design-system/Icon/arrow_down.svg';

import pxios from '../../../apis/pxios';
import Emoji from '../../../component-system/Emoji/Emoji';
import { haptic } from '../../../cores/haptic';
import { Car } from '../../../declaration/car';
import Button from '../../../design-system/Button/Button';
import Checkbox from '../../../design-system/Form/Selector/Checkbox/Checkbox';
import { ReactComponent as ConfirmPass } from '../../../design-system/Icon/confirm_pass.svg';
import Layer from '../../../design-system/Layer/Layer';
import Typography from '../../../design-system/Typography/Typography';
import { useToast } from '../../../hooks/useToast';
import { getTokenFromURLParam } from '../../../utils/getTokenFromURLParam';
import { setAuthorization } from '../../../utils/setAuthorization';
import AfterProgressPopup from '../../AfterProgressPopup/AfterProgressPopup';
import AuthPhoneNumber from '../../AuthPhoneNumber/AuthPhoneNumber';
import ReservationInput from '../../ReservationInput/ReservationInput';

import styles from './PreInspectionApplyForm.module.scss';

interface Props {
  car?: Car;
}

interface PreInspectionApplyFormInput {
  address?: string;
  raw_address: PostcodeData | null;
  reserve_at?: string;
  isCheckPrivacy: boolean;
}
const PreInspectionApplyForm: React.FC<Props> = ({ car }) => {
  const [isOpenAuthPhoneNumberPopup, setOpenAuthPhoneNumberPopup] = useState(false);
  const [isOpenAfterProgressPopup, setOpenAfterProgressPopup] = useState(false);
  const { hash_id } = useParams<{ hash_id: string }>();
  const token = getTokenFromURLParam();
  const history = useHistory();
  const toast = useToast();
  const queryClient = useQueryClient();
  // ReservationInputKey 컴포넌트를 강제 reRendering 시킬 때 사용되는 key
  const [reservationInputKey, setReservationInputKey] = useState('ReservationInputKey');

  const reservePreInspectionMutation = useMutation<
    AxiosResponse<{ is_passed: boolean }>,
    AxiosError,
    Omit<PreInspectionApplyFormInput, 'isCheckPrivacy'>
  >((data) => pxios.post(`pre_inspection/cars/${hash_id}/reserve_pre_inspection/`, data, setAuthorization(token)), {
    onSuccess: () => {
      haptic.success();
      history.push(`/cars/${hash_id}/submit-done`);
    },
  });

  const onSubmit = (values: PreInspectionApplyFormInput) => {
    reservePreInspectionMutation.mutate({
      address: values.address,
      reserve_at: values.reserve_at,
      raw_address: values.raw_address,
    });
  };

  const { values, handleSubmit, setFieldValue, submitForm } = useFormik({
    initialValues: {
      address: get(car, 'address'),
      raw_address: null,
      reserve_at: get(car, 'reserve_at'),
      isCheckPrivacy: false,
    },
    onSubmit,
  });

  /**
   * (서버) 1.신청완료 버튼 누를 때 [주소] + [시간] + [차량] 보내서 신청가능한 일정인지 판단하는 API 추가 (서버 - 호출한 로그 기록)
   * (서버) 2.calendar api 호출할 때 1번에서 생성된 로그가 없다면 모든 시간 열려있는 것으로 내려주기
   * (웹) 3.1번 api 호출 후 신청 불가능하다고 내려오면 [적절한 토스트 혹은 팝업 출력] + [선택한 시간 값 초기화] + [calendar api 다시 호출해서 선택가능 일정 갱신]
   */
  const checkPreInspectionScheduleMutation = useMutation<
    AxiosResponse<{ is_available: boolean }>,
    AxiosError,
    Omit<PreInspectionApplyFormInput, 'isCheckPrivacy'>
  >((data) => pxios.post(`pre_inspection/cars/${hash_id}/check_pre_inspection_schedule/`, data, setAuthorization(token)), {
    onSuccess: async ({ data }) => {
      if (data.is_available) {
        setOpenAuthPhoneNumberPopup(true);
        haptic.interact();
      } else {
        await queryClient.invalidateQueries({
          predicate: (query) => {
            setReservationInputKey(Math.random().toString());
            setFieldValue('reserve_at', undefined);
            return query.queryKey.indexOf(`pre_inspection/pre_inspection_calendar/?car_hash_id=${hash_id}`) !== -1;
          },
        });
        toast.fail('앗! 평가가 불가능한 일정입니다.\n일정을 다시 선택해주세요');
        haptic.failure();
      }
    },
  });

  const onChangeAddress = (address: string, rawAddress: PostcodeData) => {
    setFieldValue('address', address);
    setFieldValue('raw_address', rawAddress);
    setFieldValue('reserve_at', undefined);
  };

  const onChangeReserveAt = (reserveAt: string) => {
    setFieldValue('reserve_at', reserveAt);
  };

  const isValidatedConfirmButton = () => {
    return every([trim(values.address), trim(values.reserve_at), values.isCheckPrivacy]);
  };

  const onClickConfirmButton = () => {
    checkPreInspectionScheduleMutation.mutate({
      address: values.address,
      reserve_at: values.reserve_at,
      raw_address: values.raw_address,
    });
  };

  const onCloseAuthPopup = () => {
    setOpenAuthPhoneNumberPopup(false);
  };

  const onClickPrivacyPolicy = () => {
    window.open('https://api.heydealer.com/posts/ERJ1ZakN/');
  };

  const onClickAfterProgressPopup = () => {
    setOpenAfterProgressPopup(true);
  };
  return (
    <>
      <form onSubmit={handleSubmit}>
        <Layer variant="level_8" className={styles.layer}>
          <div className={styles.content}>
            <header className={styles.header}>
              <div className={styles.titleWrapper}>
                <Emoji name="page-facing-up" className={styles.emoji} />
                <Typography variant="Subtitle_16">방문진단 예약하기</Typography>
              </div>
              <Button
                variant={'text-gray'}
                text="이후 진행과정"
                rightIcon={<ArrowDown className={styles.arrowIcon} />}
                size="tiny"
                type="button"
                onClick={onClickAfterProgressPopup}
              />
            </header>
            <AddressInput address={values.address} onChangeAddress={onChangeAddress} />
            <div className={styles.reservationInputWrapper}>
              <ReservationInput
                key={reservationInputKey}
                rawAddress={values.raw_address}
                reserveAt={values.reserve_at}
                onChangeReserveAt={onChangeReserveAt}
              />
            </div>
          </div>
          <div className={styles.border} />
          <div className={styles.checkboxWrapper}>
            <Checkbox
              label="개인정보 처리방침 동의"
              className={styles.checkbox}
              checked={values.isCheckPrivacy}
              onChange={(e) => {
                const value = e.currentTarget.checked;
                if (value && trim(values.address) && trim(values.reserve_at)) {
                  document.documentElement.style.scrollBehavior = 'smooth';
                  window.scrollTo(0, document.body.scrollHeight);
                  document.documentElement.style.scrollBehavior = 'auto';
                }
                setFieldValue('isCheckPrivacy', value);
              }}
            />
            <Typography variant="Caption" onClick={onClickPrivacyPolicy} className={styles.privacyPolicy}>
              내용보기
            </Typography>
          </div>
          <Button
            text="신청완료"
            disabled={!isValidatedConfirmButton()}
            className={styles.button}
            onClick={onClickConfirmButton}
            type="button"
            leftIcon={<ConfirmPass className={classNames(styles.icon, { [styles.disableIcon]: !isValidatedConfirmButton() })} />}
          />
        </Layer>
      </form>
      <AuthPhoneNumber
        isOpen={isOpenAuthPhoneNumberPopup}
        onClose={onCloseAuthPopup}
        submitForm={submitForm}
        isSubmitLoading={reservePreInspectionMutation.isLoading}
      />
      <AfterProgressPopup isOpen={isOpenAfterProgressPopup} onClose={() => setOpenAfterProgressPopup(false)} />
    </>
  );
};

export default PreInspectionApplyForm;
