import React from 'react';

import classNames from 'classnames';
import { filter, map } from 'lodash';
import { DateTime } from 'luxon';

import { Timetable } from '../../../../declaration/car';
import Typography from '../../../../design-system/Typography/Typography';

import styles from './TimeTable.module.scss';

interface Props {
  onClose: () => void;
  timeTables: Timetable[] | null;
  onChangeReserveAt: (reserveAt: string) => void;
  reserveAt?: string;
}
const TimeTable: React.FC<Props> = ({ timeTables, onChangeReserveAt, onClose, reserveAt }) => {
  const dateTimeTimeTables = map(timeTables, (timeTable) => ({
    dateTime: DateTime.fromFormat(timeTable.time, 'yyyy-MM-dd HH:mm:ss'),
    isAvailable: timeTable.is_available,
  }));
  const amTimeTables = filter(dateTimeTimeTables, (timeTable) => timeTable.dateTime.hour < 12);
  const pmTimeTables = filter(dateTimeTimeTables, (timeTable) => timeTable.dateTime.hour >= 12);

  const onClickTime = (time: string) => {
    onChangeReserveAt(time);
    onClose();
  };
  const renderTimes = (timeTables: { dateTime: DateTime; isAvailable: boolean }[]) => {
    return (
      <div className={styles.times}>
        {map(timeTables, (timeTable) => {
          const isSelected = reserveAt
            ? timeTable.dateTime.hasSame(DateTime.fromFormat(reserveAt, 'yyyy-MM-dd HH:mm:ss'), 'minute')
            : null;

          const disable = !timeTable.isAvailable;
          return (
            <div
              key={timeTable.dateTime.toString()}
              className={classNames(styles.time, {
                [styles.disable]: disable,
                [styles.selected]: isSelected,
              })}
              onClick={() => {
                if (!disable) {
                  onClickTime(timeTable.dateTime.toFormat('yyyy-MM-dd HH:mm:ss'));
                }
              }}
            >
              <Typography variant="Body_14" className={classNames({ [styles.disable]: disable, [styles.selected]: isSelected })}>
                {timeTable.dateTime.toFormat('HH:mm')}
              </Typography>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.timetable}>
        <Typography variant="Body_14" className={styles.label}>
          오전
        </Typography>
        {renderTimes(amTimeTables)}
      </div>
      <div className={styles.timetable}>
        <Typography variant="Body_14" className={styles.label}>
          오후
        </Typography>
        {renderTimes(pmTimeTables)}
      </div>
    </div>
  );
};

export default TimeTable;
