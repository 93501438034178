import React, { useState } from 'react';

import classNames from 'classnames';
import { findIndex } from 'lodash';
import { DateTime } from 'luxon';
import AnimateHeight from 'react-animate-height';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import { ReactComponent as ArrowDown } from 'design-system/Icon/arrow_down.svg';
import { ReactComponent as CalendarIcon } from 'design-system/Icon/calendar.svg';
import { ReactComponent as StrokeTimer } from 'design-system/Icon/stoke_timer.svg';

import { PreInspectionCalendar, Timetable } from '../../../declaration/car';
import Typography from '../../../design-system/Typography/Typography';
import { useMeasurePolyfill } from '../../../hooks/useMeasurePolyfill';
import { usePrevious } from '../../../hooks/usePrevious';
import Modal from '../../Modal/Modal';

import Calendar from './Calendar/Calendar';
import TimeTable from './TimeTable/TimeTable';

import styles from './ReservationModal.module.scss';

interface Props {
  isVisible: boolean;
  onClose: () => void;
  rawAddress: PostcodeData | null;
  reserveAt?: string;
  onChangeReserveAt: (reserveAt: string) => void;
  setDisable: React.Dispatch<React.SetStateAction<boolean>>;
  setFocus: React.Dispatch<React.SetStateAction<boolean>>;
}

type Tab = 'date' | 'time';
const PADDING_HEIGHT = 32 * 2;
const ReservationModal: React.FC<Props> = ({
  isVisible,
  onClose,
  rawAddress,
  reserveAt,
  onChangeReserveAt,
  setDisable,
  setFocus,
}) => {
  const { hash_id } = useParams<{ hash_id: string }>();
  const [tab, setTab] = useState<Tab>('date');
  const [selectedDate, setSelectedDate] = useState<null | DateTime>(null);
  const [timeTables, setTimeTables] = useState<null | Timetable[]>(null);
  const isDateSelected = tab === 'date';
  const [ref, bounds] = useMeasurePolyfill();

  const previousRawData = usePrevious(rawAddress);

  const { data } = useQuery<PreInspectionCalendar[]>(
    `pre_inspection/pre_inspection_calendar/?car_hash_id=${hash_id}&raw_address=${JSON.stringify(rawAddress)}`,
    {
      enabled: !!rawAddress && previousRawData !== rawAddress,
      onSuccess: () => {
        setDisable(false);
        setFocus(true);
      },
      onError: () => {
        setDisable(true);
        setFocus(false);
      },
    }
  );

  const isToday = (dateTime: DateTime) => {
    return DateTime.local().hasSame(dateTime, 'day');
  };

  const isTomorrow = (dateTime: DateTime) => {
    return DateTime.local().plus({ day: 1 }).hasSame(dateTime, 'day');
  };

  const onClickTab = (tab: Tab) => {
    setTab(tab);
  };

  const onClickDay = (dateTime: DateTime) => {
    setSelectedDate(dateTime);
    const findPreInspectionCalendarIndex = findIndex(data, (preInspectionCalendar) =>
      dateTime.hasSame(DateTime.fromFormat(preInspectionCalendar.date, 'yyyy-MM-dd HH:mm:ss'), 'day')
    );

    if (data && findPreInspectionCalendarIndex !== -1) {
      setTimeTables(data[findPreInspectionCalendarIndex].timetable);
    }

    setTab('time');
  };

  const onClickTime = () => {
    if (selectedDate) {
      onClickTab('time');
    }
  };

  return (
    <Modal isVisible={isVisible} onClose={onClose} className={styles.modal} style={{ height: bounds.height + PADDING_HEIGHT }}>
      <div ref={ref}>
        <div className={styles.subTitleWrapper} onClick={() => onClickTab('date')}>
          <div className={styles.subTitle}>
            <CalendarIcon className={styles.icon} />
            <Typography variant="Subtitle_1">
              {selectedDate ? (
                <>
                  {selectedDate.toFormat('M월 d일 EEEE')}
                  {isToday(selectedDate) && <span>&nbsp;(오늘)</span>}
                  {isTomorrow(selectedDate) && <span>&nbsp;(내일)</span>}
                </>
              ) : (
                <span className={styles.active}>방문 날짜</span>
              )}
            </Typography>
          </div>
          <ArrowDown className={classNames(classNames(styles.arrowIcon, { [styles.isOpen]: tab === 'date' }))} />
        </div>
        <AnimateHeight height={isDateSelected ? 'auto' : 0}>
          <Calendar onClickDay={onClickDay} preInspectionCalendars={data} selectedDate={selectedDate} />
        </AnimateHeight>
        <div className={styles.border} />
        <div className={styles.subTitleWrapper} onClick={onClickTime}>
          <div className={styles.subTitle}>
            <StrokeTimer className={styles.icon} />
            <Typography variant="Subtitle_1" className={classNames({ [styles.active]: !isDateSelected })}>
              시간 선택
            </Typography>
          </div>
          <ArrowDown className={classNames(styles.arrowIcon, { [styles.isOpen]: tab === 'time' })} />
        </div>
        <AnimateHeight height={isDateSelected ? 0 : 'auto'}>
          <TimeTable timeTables={timeTables} reserveAt={reserveAt} onChangeReserveAt={onChangeReserveAt} onClose={onClose} />
        </AnimateHeight>
      </div>
    </Modal>
  );
};

export default ReservationModal;
