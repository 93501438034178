import { size, replace } from 'lodash';

export const toFormattedInputPhoneNumber = (phoneNumberInput: string) => {
  const phoneNumber = replace(phoneNumberInput, /-/g, '');
  const length = size(phoneNumber);
  if (length > 4 && length < 8) {
    return `${phoneNumber.substr(0, 3)}-${phoneNumber.substr(3, 4)}`;
  } else if (length >= 8) {
    return `${phoneNumber.substr(0, 3)}-${phoneNumber.substr(3, 4)}-${phoneNumber.substr(7, 4)}`;
  } else {
    return phoneNumber;
  }
};
