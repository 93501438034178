import React from 'react';

import { ReactComponent as Download } from 'design-system/Icon/download.svg';
import guideToApp from 'images/guide_to_app.jpg';

import Button from '../../../design-system/Button/Button';
import Layer from '../../../design-system/Layer/Layer';
import Typography from '../../../design-system/Typography/Typography';

import styles from './GuideToApp.module.scss';

interface Props {
  installLink?: string;
}
const GuideToApp: React.FC<Props> = ({ installLink }) => {
  return (
    <Layer variant="level_8" className={styles.layer}>
      <Typography variant="Subtitle_16">
        대신, 앱으로 직접 견적요청하는 <br />
        방법은 어떠세요?
      </Typography>
      <Typography variant="Body_14" className={styles.description}>
        6가지만 입력하면, 최대 30개 견적을 받아요.
      </Typography>
      <img src={guideToApp} alt="앱으로 견적요청" className={styles.guideToApp} />
      <Button
        leftIcon={<Download />}
        variant="primary-brandcolor"
        text="앱으로 견적요청"
        className={styles.button}
        href={installLink}
      />
    </Layer>
  );
};

export default GuideToApp;
