import React from 'react';

import section1Img from 'images/home/section1.png';

import Typography from '../../../design-system/Typography/Typography';

import styles from './Section1.module.scss';

const Section1: React.FC = () => {
  return (
    <div className={styles.section1}>
      <img src={section1Img} alt="차량 정검하는 사진" className={styles.section1Image} />
      <div className={styles.content}>
        <Typography variant={'Headline_32'} className={styles.title}>
          새로운 내차팔기 방법, <br />
          헤이딜러 ZERO
        </Typography>
        <Typography variant="Body_1" className={styles.description}>
          현장감가 ZERO <br />
          딜러만남 ZERO
        </Typography>
      </div>
      <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" className={styles.mouse}>
        <rect x="11" y="4" width="10" height="14" rx="5" stroke="#fff" />
        <path
          d="M13.3157 21.3999L16 23.7156L18.6842 21.3999"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          className={styles.arrow}
        />
        <path
          d="M13.3157 24.6843L16 27L18.6842 24.6843"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          className={styles.arrow}
        />
        <line x1="16" y1="7.5" x2="16" y2="9.5" stroke="#fff" strokeLinecap="round" className={styles.ball} />
      </svg>
    </div>
  );
};

export default Section1;
