import React, { ForwardedRef, forwardRef, ForwardRefRenderFunction, HTMLAttributes, useEffect, useRef, useState } from 'react';

import classNames from 'classnames';
import { map, size } from 'lodash';
import { animated, config, useSpring } from 'react-spring';

import { isCompletedHangle, isHangle } from '../../../utils/checkHangle';
import Placeholder from '../Placeholder/Placeholder';

import styles from './GeneralCarNumber.module.scss';

export interface ICarNumber {
  region1?: string;
  region2?: string;
  head?: string;
  middle?: string;
  tail?: string;
  carNumber: string;
  isInputFocused: boolean;
  isBusiness: boolean;
}

const TWO_DIGIT_NUMBER_LEFT = 40;
const THREE_DIGIT_NUMBER_LEFT = 25;
const TWO_DIGIT_NUMBER_WITH_KOREAN_LEFT = 30;

const GeneralCarNumber: ForwardRefRenderFunction<HTMLSpanElement, ICarNumber & HTMLAttributes<HTMLSpanElement>> = (
  { region1, region2, head, middle, tail, carNumber, isInputFocused, isBusiness },
  ref
) => {
  const carNumberRef = useRef<HTMLDivElement>(null);
  const [carNumberWidth, setCarNumberWidth] = useState(0);

  useEffect(() => {
    const boundingClientRect = carNumberRef.current?.getBoundingClientRect();
    if (boundingClientRect) {
      const { width } = boundingClientRect;
      setCarNumberWidth(width);
    }
  }, [carNumber]);

  const getCursorHeight = () => {
    // 서
    if (region1 && size(carNumber) === 1) {
      return '43%';
      // 서ㅇ
    } else if (region1 && region2 && size(carNumber) === 2 && !isCompletedHangle(region2)) {
      return '43%';
      // 12
    } else if (size(head) === 2 && size(carNumber) === 2) {
      return '55%';
      // 123
    } else if (size(head) === 3 && size(carNumber) === 3) {
      return '55%';
    } else {
      return '80%';
    }
  };

  const getCursorTopLeft = () => {
    const left = carNumberWidth ? carNumberWidth : -4;
    const firstChar = carNumber[0];
    const secondChar = carNumber[1];

    // ㅅ
    if (region1 && size(carNumber) <= 1 && !isCompletedHangle(firstChar)) {
      return [0, left];
      // 서
    } else if (region1 && size(carNumber) <= 1 && isCompletedHangle(firstChar)) {
      return [50, 0];
      // 서ㅇ
    } else if (region1 && region2 && size(carNumber) === 2 && !isCompletedHangle(secondChar)) {
      return [50, left];
      // 서우
    } else if (region1 && region2 && size(carNumber) === 2 && isCompletedHangle(secondChar)) {
      return [8, left];
      // 12
    } else if (size(head) === 2 && size(carNumber) === 2) {
      return [24, left];
      // 123
    } else if (size(head) === 3 && size(carNumber) === 3) {
      return [24, left];
    } else {
      return [8, left];
    }
  };

  const renderGeneralCarNumber = () =>
    map(carNumber, (char, index) =>
      isHangle(char) ? (
        <span key={index + char} className={classNames(styles.hangle, { [styles.isCompletedHangle]: isCompletedHangle(char) })}>
          {char}
        </span>
      ) : (
        <span key={index + char} className={styles.number}>
          {char}
        </span>
      )
    );

  const renderRegionCarNumber = () => {
    const firstChar = carNumber[0];
    const secondChar = carNumber[1];
    const remainChars = carNumber.substring(2);
    return (
      <div className={styles.oldCarNumberWrapper}>
        <div className={styles.vertical}>
          <span className={styles.smallHangle}>{firstChar}</span>
          <span className={styles.smallHangle}>{secondChar}</span>
        </div>
        <div className={styles.horizontal}>
          {map(remainChars, (char, index) => (
            <span key={char + index} className={isHangle(char) ? styles.hangle : styles.number}>
              {char}
            </span>
          ))}
        </div>
      </div>
    );
  };

  const cursorStyles = useSpring({
    topLeft: getCursorTopLeft(),
    height: getCursorHeight(),
    config: config.stiff,
  });

  const getCarNumberPositionLeft = () => {
    if (region1 && region2 && size(head) === 2) {
      return TWO_DIGIT_NUMBER_WITH_KOREAN_LEFT;
    } else {
      return size(head) === 3 ? THREE_DIGIT_NUMBER_LEFT : TWO_DIGIT_NUMBER_LEFT;
    }
  };
  const carNumberPositionStyles = useSpring({
    left: getCarNumberPositionLeft(),
  });

  return (
    <div className={styles.container}>
      <div className={classNames(styles.carNumberInput, { [styles.business]: isBusiness })} />
      <div className={styles.border} />
      <span className={classNames(styles.dot, styles.leftDot, { [styles.businessDot]: isBusiness })} />
      <span className={classNames(styles.dot, styles.rightDot)} />
      <animated.div style={carNumberPositionStyles} className={styles.carNumber} ref={carNumberRef}>
        <animated.span
          style={{
            height: cursorStyles.height,
            left: cursorStyles.topLeft.to((top, left) => left),
            top: cursorStyles.topLeft.to((top) => `${top}%`),
          }}
          className={classNames(styles.cursor, 'blinkAnimation', {
            [styles.isFocused]: isInputFocused,
          })}
          ref={ref}
        />
        {region1 ? renderRegionCarNumber() : renderGeneralCarNumber()}
      </animated.div>
      {size(carNumber) <= 0 && <Placeholder />}
    </div>
  );
};

export default forwardRef(GeneralCarNumber);
