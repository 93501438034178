import React, { FC } from 'react';

import classNames from 'classnames';

import styles from './Placeholder.module.scss';

const Placeholder: FC = () => {
  return (
    <div className={styles.placeholder}>
      <span className={styles.number}>00</span>
      <span className={classNames(styles.isCompletedHangle, styles.hangle)}>가</span>
      <span className={styles.number}>1234</span>
    </div>
  );
};

export default Placeholder;
