export const getTokenFromURLParam = () => {
  const query = new URLSearchParams(window.location.search);
  const token = query.get('token');
  if (token) {
    localStorage.setItem('token', token);
    return token;
  } else {
    return localStorage.getItem('token');
  }
};
