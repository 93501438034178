import React, { useEffect, useState } from 'react';

import { useInView } from 'react-intersection-observer';

import Section4 from 'components/home/Section4/Section4';

import BottomSheetDialog from '../component-system/BottomSheetDialog/BottomSheetDialog';
import CarInfoLoader from '../components/CarInfoLoader/CarInfoLoader';
import CarNumberForm from '../components/CarNumberForm/CarNumberForm';
import Footer from '../components/Footer/Footer';
import Section1 from '../components/home/Section1/Section1';
import Section2 from '../components/home/Section2/Section2';
import Section3 from '../components/home/Section3/Section3';
import Section5 from '../components/home/Section5/Section5';
import Section6 from '../components/home/Section6/Section6';
import Layout from '../components/Layout/Layout';

import styles from './Home.module.scss';

export interface HashIdAndToken {
  hashId: string | null;
  token: string | null;
}
const Home: React.FC = () => {
  const [isOpenOwnerNamePopup, setOpenOwnerNamePopup] = useState<boolean>(false);
  const [hashIdAndToken, setHashIdAndToken] = useState<HashIdAndToken>({ hashId: null, token: null });
  const [isVisibleCarNumber, setIsVisibleCarNumber] = useState(false);
  const [section2Ref, section2InView] = useInView({
    threshold: 0.6,
    triggerOnce: true,
  });

  const onClickConfirmButton = () => {
    setOpenOwnerNamePopup(true);
  };

  const onCloseOwnerNamePopup = () => {
    window.location.href = '/';
  };

  const onScroll = () => {
    if (window.scrollY > 100) {
      setIsVisibleCarNumber(true);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', onScroll);
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);
  return (
    <Layout className={styles.home}>
      <section className={styles.content}>
        <Section1 />
        <Section2 ref={section2Ref} section2InView={section2InView} />
        <Section3 />
        <Section4 />
        <Section5 />
        <Section6 />
        <Footer />
        <BottomSheetDialog isOpen={isOpenOwnerNamePopup} onClose={onCloseOwnerNamePopup} enableCloseOnOutsideClick={false}>
          <CarInfoLoader hashIdAndToken={hashIdAndToken} />
        </BottomSheetDialog>
        <CarNumberForm
          onClickConfirmButton={onClickConfirmButton}
          onClose={() => setOpenOwnerNamePopup(false)}
          setHashIdAndToken={setHashIdAndToken}
          section2InView={section2InView || isVisibleCarNumber}
        />
      </section>
    </Layout>
  );
};

export default Home;
