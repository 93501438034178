import React, { useEffect } from 'react';

import classNames from 'classnames';
import ReactGA from 'react-ga4';
import { useLocation } from 'react-router-dom';

import styles from './Layout.module.scss';

interface Props {
  className?: string;
}
const Layout: React.FC<Props> = ({ children, className }) => {
  const { key, pathname, search } = useLocation();

  useEffect(() => {
    if (process.env.REACT_APP_ENV === 'production') {
      ReactGA.send({ hitType: 'pageview', page: pathname + search });
    }
  }, [pathname, search]);

  return <div className={classNames(styles.layout, className)}>{children}</div>;
};

export default Layout;
