import { useEffect } from 'react';

import { useLocation } from 'react-router-dom';

import initChannelIO from '../utils/channelIO';

const useChatting = (hashId: string, carNumber: string, isChatAvailable: boolean) => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const chatting = query.get('chatting');
  const isProduction = process.env.ENV === 'production';
  useEffect(() => {
    if (chatting === 'open' && isChatAvailable) {
      initChannelIO();
      window.ChannelIO('boot', {
        pluginKey: 'aa071ec2-70f4-487c-80b0-ec3271be6061',
        memberId: `${isProduction ? `web_${hashId}` : `test_web_${hashId}`}`,
        hideChannelButtonOnBoot: true,
        profile: {
          name: `${isProduction ? `web_${hashId}` : `test_web_${hashId}`}`,
          preInspectionCarNumber: carNumber,
        },
      });
      window.ChannelIO('showMessenger');
    }
  }, [chatting, isChatAvailable]);
};

export default useChatting;
