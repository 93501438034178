import { every } from 'lodash';

const validateCarNumberFormat = (carNumber: string): boolean => {
  const charList = carNumber.split('');
  const isNumber = (char: string | undefined) => {
    const reg = /^\d$/;
    return char ? reg.test(char) : true;
  };

  const isNumberOrHangle = (char: string | undefined) => {
    const reg = /[ㄱ-ㅎㅏ-ㅣ가-힣0-9]$/;
    return char ? reg.test(char) : true;
  };

  const isHangle = (char: string | undefined) => {
    const reg = /[ㄱ-ㅎㅏ-ㅣ가-힣]$/;
    return char ? reg.test(char) : true;
  };

  const firstChar = charList[0];
  const secondChar = charList[1];
  const thirdDigit = charList[2];
  const fourthDigit = charList[3];
  const fifthDigit = charList[4];
  const sixthDigit = charList[5];
  const seventhDigit = charList[6];
  const eightyDigit = charList[7];
  const ninthDigit = charList[8];

  return every([
    isNumberOrHangle(firstChar),
    isNumberOrHangle(secondChar),
    isHangle(firstChar) && isHangle(secondChar) ? isNumber(thirdDigit) : isNumberOrHangle(thirdDigit),
    isNumber(thirdDigit) ? isNumberOrHangle(fourthDigit) : isNumber(fourthDigit),
    isNumber(thirdDigit) && isNumber(fourthDigit) ? isHangle(fifthDigit) : isNumber(fifthDigit),
    isNumber(fifthDigit) ? isNumberOrHangle(sixthDigit) : isNumber(sixthDigit),
    isNumber(seventhDigit),
    isNumber(eightyDigit),
    isNumber(ninthDigit),
  ]);
};

export default validateCarNumberFormat;
