import React, { HTMLProps, memo, ReactNode } from 'react';

import classNames from 'classnames';

import Typography from '../../Typography/Typography';

import styles from './InputField.module.scss';

interface Props {
  // style
  size?: 'small' | 'big';
  warning?: boolean;
  rightAddon?: ReactNode;

  // customize
  className?: string;
}

// DESC
//  html <input> 의 size 속성은 안 먹습니다 (Omit 함)

const InputField: React.FC<Props & Omit<HTMLProps<HTMLInputElement>, 'size'>> = memo(
  ({ className, size = 'small', warning = false, rightAddon, ...otherProps }) => {
    const combinedClassName = classNames(warning && styles.warning, otherProps.disabled && styles.disabled, {
      [styles.rightAddon]: rightAddon,
    });

    return (
      <div className={classNames(styles.wrapper, combinedClassName, className)}>
        <input {...otherProps} className={classNames(styles.input, styles[size])} />
        {rightAddon}
      </div>
    );
  }
);

export default InputField;
