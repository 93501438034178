import React, { forwardRef, ForwardRefRenderFunction, HTMLAttributes, useEffect, useRef, useState } from 'react';

import classNames from 'classnames';
import { size } from 'lodash';
import { animated, config, useSpring } from 'react-spring';

import { isCompletedHangle } from '../../../utils/checkHangle';

import styles from './OldCarNumber.module.scss';

interface Props {
  region1?: string;
  region2?: string;
  head?: string;
  middle?: string;
  tail?: string;
  carNumber: string;
  isInputFocused: boolean;
}

const OldCarNumber: ForwardRefRenderFunction<HTMLSpanElement, Props & HTMLAttributes<HTMLSpanElement>> = (
  { region1, region2, head, middle, tail, carNumber, isInputFocused },
  ref
) => {
  const carNumberRef = useRef<HTMLDivElement>(null);
  const [carNumberWidth, setCarNumberWidth] = useState(0);

  useEffect(() => {
    const boundingClientRect = carNumberRef.current?.getBoundingClientRect();
    if (boundingClientRect) {
      const { width } = boundingClientRect;
      setCarNumberWidth(width);
    }
  }, [carNumber]);

  const cursorStyles = useSpring({
    left: carNumberWidth ? carNumberWidth : -4,
    config: config.stiff,
  });

  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <span className={classNames(styles.dot, styles.leftDot)} />
        <div className={classNames(styles.regionWrapper)}>
          <div className={styles.region}>{region1}</div>
          <div className={styles.region}>{region2}</div>
        </div>
        <div className={classNames(styles.headNumber)}>{head}</div>
        <span className={classNames(styles.dot, styles.rightDot)} />
      </div>
      <div className={styles.row}>
        <span className={styles.middleHangle}>{middle}</span>
        <div className={styles.carNumber} ref={carNumberRef}>
          <animated.span
            style={{
              left: cursorStyles.left.to((left) => left),
            }}
            className={classNames(styles.cursor, 'blinkAnimation', {
              [styles.isFocused]: isInputFocused,
            })}
            ref={ref}
          />
          <span className={styles.tail}>&nbsp;{tail}</span>
        </div>
      </div>
    </div>
  );
};

export default forwardRef(OldCarNumber);
