import React, { useEffect, useRef, useState } from 'react';

import classNames from 'classnames';

import { ReactComponent as Location } from 'design-system/Icon/location.svg';

import { haptic } from '../../cores/haptic';
import Typography from '../../design-system/Typography/Typography';
import { useLandscape } from '../../hooks/useLandscape';
import { useWindowSize } from '../../hooks/useWindowSize';
import Modal from '../Modal/Modal';
import Ripple from '../Ripple/Ripple';

import styles from './AddressInput.module.scss';

interface Props {
  address?: string;
  onChangeAddress: (address: string, rawAddress: PostcodeData) => void;
}

const POSTCODE_MODAL_HEIGHT = 420;
const DEFAULT_POSTCODE_MODAL_HEIGHT = 380;
const DEFAULT_POSTCODE_MODAL_WIDTH = 340;
const POSTCODE_HORIZONTAL_PADDING = 32;
const BREAK_POINT = 640;
const CONTAINER_PADDING = 10;
const AddressInput: React.FC<Props> = ({ address, onChangeAddress }) => {
  const [isFocus, setFocus] = useState(false);
  const isLandscape = useLandscape();
  const addressPopupWidth = isLandscape ? 320 : 640;
  const [openPostCodeModal, setPostCodeModal] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const size = useWindowSize();

  const getWidth = (windowWidth: number) => (windowWidth > BREAK_POINT ? addressPopupWidth : windowWidth);
  const getHeight = (windowWidth: number) => (windowWidth > BREAK_POINT ? addressPopupWidth : POSTCODE_MODAL_HEIGHT);

  const width = size.width ? getWidth(size.width) : DEFAULT_POSTCODE_MODAL_WIDTH;
  const height = size.width ? getHeight(size.width) : DEFAULT_POSTCODE_MODAL_HEIGHT;

  const onComplete = (data: PostcodeData) => {
    onChangeAddress(data.address, data);
    if (wrapperRef.current) {
      wrapperRef.current.style.display = 'none';
      setPostCodeModal(false);
    }
  };
  const handleAddressInput = () => {
    setPostCodeModal(true);
    haptic.interact();
  };

  useEffect(() => {
    if (openPostCodeModal && wrapperRef.current) {
      new daum.Postcode({
        oncomplete: onComplete,
        width: '100%',
        height: '100%',
      }).embed(wrapperRef.current);
      initLayerPosition();
      wrapperRef.current.style.display = 'block';
    }
  }, [openPostCodeModal]);

  function initLayerPosition() {
    if (wrapperRef.current) {
      wrapperRef.current.style.width = width - POSTCODE_HORIZONTAL_PADDING + 'px';
      wrapperRef.current.style.height = height - CONTAINER_PADDING + 'px';
      wrapperRef.current.style.left = ((window.innerWidth || document.documentElement.clientWidth) - width) / 2 + 'px';
      wrapperRef.current.style.top = ((window.innerHeight || document.documentElement.clientHeight) - height) / 2 + 'px';
    }
  }
  return (
    <>
      <input
        type="text"
        className={styles.hiddenInput}
        autoFocus
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
        id="address-input"
        readOnly={true}
      />
      <label
        className={classNames(styles.input, { [styles.focus]: isFocus })}
        onClick={handleAddressInput}
        htmlFor="address-input"
      >
        <Location className={styles.icon} />
        <Typography variant="Body_14">
          {address ? address : <span className={styles.placeholder}>어디로 방문드릴까요?</span>}
        </Typography>
        <Ripple />
      </label>
      <Modal
        isVisible={openPostCodeModal}
        onClose={() => setPostCodeModal(false)}
        style={{ width: width - POSTCODE_HORIZONTAL_PADDING, height }}
      >
        <div className={styles.container}>
          <div ref={wrapperRef} />
        </div>
      </Modal>
    </>
  );
};

export default AddressInput;
