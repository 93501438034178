import React, { ChangeEvent, useEffect, useRef, useState } from 'react';

import { get, includes, size } from 'lodash';

import { isCompletedHangle } from '../../utils/checkHangle';

import DiplomacyPopup from './DiplomacyPopup/DiplomacyPopup';
import GeneralCarNumber from './GeneralCarNumber/GeneralCarNumber';
import OldCarNumber from './OldCarNumber/OldCarNumber';
import validateCarNumberFormat from './validate-car-number-format';

import styles from './CarNumberInput.module.scss';

interface Props {
  onChange: (carNumber: string) => void;
}

const REGION_REGEX_TEXT = '^([가-힣][가-힣])?';
const OLD_REGION_REGEX_TEXT = '^([가-힣][가-힣])';
const HEAD_REGION_TEXT = '[0-9]{2,3}';
const OLD_HEAD_REGION_TEXT = '[0-9]';
const MIDDLE_REGION_TEXT = '[가-힣]';
const SPECIAL_UNIT = '[^A-Za-z0-9ㄱ-ㅎㅏ-ㅣ가-힣]';
const KOREA_UNIT = `[ㄱ-ㅎ가-힣]${SPECIAL_UNIT}?`;

const region1CaptureRegExp = new RegExp(`(^${KOREA_UNIT})`);
const region2CaptureRegExp = new RegExp(`^[가-힣](${KOREA_UNIT})`);
const headCaptureRegExp = new RegExp(`${REGION_REGEX_TEXT}([0-9]{1,3})`);
const middleCaptureRegExp = new RegExp(
  `((${REGION_REGEX_TEXT}${HEAD_REGION_TEXT})|(${OLD_REGION_REGEX_TEXT}${OLD_HEAD_REGION_TEXT}))(${KOREA_UNIT})`
);
const tailCaptureRegExp = new RegExp(
  `((${REGION_REGEX_TEXT}${HEAD_REGION_TEXT})|(${OLD_REGION_REGEX_TEXT}${OLD_HEAD_REGION_TEXT}))${MIDDLE_REGION_TEXT}([0-9]{1,4})`
);

const CarNumberInput: React.FC<Props> = ({ onChange }) => {
  const [carNumber, setCarNumber] = useState('');
  const cursorRef = useRef<HTMLDivElement>(null);
  const [isInputFocused, setIsInputFocused] = useState(false);
  const [isOpenDiplomacyPopup, setOpenDiplomacyPopup] = useState(false);

  const inputRef = useRef<HTMLInputElement>(null);

  const region1 = get(carNumber?.match(region1CaptureRegExp), 0);
  const region2 = get(carNumber?.match(region2CaptureRegExp), 1);
  const head = get(carNumber?.match(headCaptureRegExp), 2);
  const middle = get(carNumber?.match(middleCaptureRegExp), 6);
  const tail = get(carNumber?.match(tailCaptureRegExp), 6);

  const resetCursorAnimation = () => {
    if (cursorRef.current) {
      cursorRef.current.classList.remove('blinkAnimation');
      void cursorRef.current.offsetWidth;
      cursorRef.current.classList.add('blinkAnimation');
    }
  };

  const onChangeCarNumber = (e: ChangeEvent<HTMLInputElement>) => {
    const targetCarNumber = e.currentTarget.value.replace(/[^ㄱ-ㅎㅏ-ㅣ가-힣0-9]/g, '');
    if (validateCarNumberFormat(targetCarNumber) && size(targetCarNumber) < 10 && !targetCarNumber.match(/[\d]{5}/g)) {
      onChange(targetCarNumber);
      setCarNumber(targetCarNumber);
    }

    if (includes(targetCarNumber, '외교')) {
      setOpenDiplomacyPopup(true);
      return;
    }

    resetCursorAnimation();
  };

  const renderCarNumber = () => {
    if (region1 && region2 && isCompletedHangle(middle) && !includes('바사아자배', middle)) {
      return (
        <OldCarNumber
          region1={region1}
          region2={region2}
          head={head}
          middle={middle}
          tail={tail}
          carNumber={carNumber}
          isInputFocused={isInputFocused}
          ref={cursorRef}
        />
      );
    } else {
      return (
        <GeneralCarNumber
          region1={region1}
          region2={region2}
          head={head}
          middle={middle}
          tail={tail}
          carNumber={carNumber}
          isInputFocused={isInputFocused}
          ref={cursorRef}
          isBusiness={includes('바사아자배', middle)}
        />
      );
    }
  };

  const onFocus = () => {
    setIsInputFocused(true);
  };

  const onBlur = () => {
    setIsInputFocused(false);
  };

  return (
    <>
      {/*오토컴플리트 제거 시작*/}
      <input style={{ display: 'none' }} aria-hidden="true" />
      <input type="password" style={{ display: 'none' }} aria-hidden="true" />
      {/*오토컴플리트 제거 끝*/}
      <label className={styles.container} htmlFor="carNumberInput">
        {/*뒤쪽 레이어에 숨겨져 있는 input*/}
        <input
          style={{ fontSize: 16 }}
          autoComplete="off"
          autoCapitalize="off"
          type="text"
          name="carNumberInput"
          id="carNumberInput"
          className={styles.hiddenInput}
          value={carNumber}
          ref={inputRef}
          onChange={onChangeCarNumber}
          onFocus={onFocus}
          onBlur={onBlur}
        />
        {renderCarNumber()}
      </label>
      <DiplomacyPopup isVisible={isOpenDiplomacyPopup} onClose={() => setOpenDiplomacyPopup(false)} />
    </>
  );
};

export default CarNumberInput;
