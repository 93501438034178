import React, { useEffect } from 'react';

import { DateTime } from 'luxon';

import styles from './Timer.module.scss';

interface Props {
  time: number;
  onChange: (time: number) => void;
}

const Timer: React.FC<Props> = ({ time, onChange }) => {
  useEffect(() => {
    let intervalId: NodeJS.Timeout;
    if (time > 0) {
      intervalId = setInterval(() => {
        onChange(time - 1);
      }, 1000);
    }
    return () => clearInterval(intervalId);
  }, [time]);

  return <div className={styles.timer}>{DateTime.fromSeconds(time).toFormat('mm:ss')}</div>;
};

export default Timer;
