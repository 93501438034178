import React, { useEffect, useState } from 'react';

import classNames from 'classnames';
import { get } from 'lodash';
import { useParams } from 'react-router-dom';
import { useTransition, a } from 'react-spring';

import { ReactComponent as ConfirmFailIcon } from 'design-system/Icon/confirm_fail.svg';
import { ReactComponent as CheckIcon } from 'design-system/Icon/confirm_pass.svg';

import Layout from '../../components/Layout/Layout';
import GuideToApp from '../../components/result/GuideToApp/GuideToApp';
import PreInspectionApplyForm from '../../components/result/PreInspectionApplyForm/PreInspectionApplyForm';
import Typography from '../../design-system/Typography/Typography';
import { useCar } from '../../hooks/api/useCar';
import { useLogMutation } from '../../hooks/api/useLogMutation';
import { getTokenFromURLParam } from '../../utils/getTokenFromURLParam';

import styles from './Result.module.scss';

const Result: React.FC = () => {
  const { hash_id } = useParams<{ hash_id: string }>();

  const { data } = useCar(hash_id, getTokenFromURLParam());
  const currentStep = get(data, 'current_step');
  const preInspectionStandards = get(data, 'pre_inspection_standards');
  const installLink = get(data, 'install_link');

  const logMutation = useLogMutation(getTokenFromURLParam());
  const [viewSection2, setViewSection2] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (currentStep === 'completed' || currentStep === 'reserve_pre_inspection') {
      logMutation.mutate({ car: hash_id, type: 'web_pre_inspection_available' });
    }
  }, [currentStep]);

  const renderTitle = () => {
    switch (currentStep) {
      case 'unavailable': {
        return (
          <Typography variant="Headline_24">
            안타깝게도 <br />
            방문진단이 불가능해요 😢
          </Typography>
        );
      }
      case 'completed':
      case 'reserve_pre_inspection': {
        return (
          <>
            <Typography variant="Headline_24">
              축하드려요! 🎉 <br />
              방문진단 가능한 차예요.
            </Typography>
          </>
        );
      }
      default: {
        return (
          <Typography variant="Headline_24">
            안타깝게도 <br />
            방문진단이 불가능해요 😢
          </Typography>
        );
      }
    }
  };

  const renderContent = () => {
    switch (currentStep) {
      case 'unavailable': {
        return <GuideToApp installLink={installLink} />;
      }
      case 'completed':
      case 'reserve_pre_inspection': {
        return <PreInspectionApplyForm car={data} />;
      }
      default: {
        return <GuideToApp installLink={installLink} />;
      }
    }
  };

  const preInspectionStandardsTransitions = useTransition(preInspectionStandards, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    delay: 500,
    trail: 300,
    onRest: (_: any, controller: any, what: any) => {
      setViewSection2(true);
    },
  });

  return (
    <Layout className={styles.layout}>
      <div className={styles.section1}>
        <div className={classNames(styles.title, { [styles.view]: currentStep })}>{renderTitle()}</div>
        <ul className={styles.standardList}>
          {preInspectionStandardsTransitions((transitionsStyles, item) => {
            return (
              <a.li className={classNames(styles.listItem)} style={transitionsStyles}>
                {item?.is_passed ? <CheckIcon className={styles.pass} /> : <ConfirmFailIcon className={styles.failed} />}
                {item?.description}
              </a.li>
            );
          })}
        </ul>
        {currentStep === 'completed' ||
          (currentStep === 'reserve_pre_inspection' && (
            <div className={classNames(styles.info, { [styles.viewSection2]: viewSection2 })}>
              <Typography variant="Body_14">
                서비스 이용료는 한시적 <span className={styles.free}>무료</span>예요. 진단 후 판매를 안하셔도 비용이 없어요.
              </Typography>
            </div>
          ))}
      </div>
      <div className={classNames(styles.section2, { [styles.viewSection2]: viewSection2 })}>{renderContent()}</div>
    </Layout>
  );
};

export default Result;
