// Android 와 동일하게 구현하려 노력

// enum class Type(val timings: LongArray, val amplitudes: IntArray) {
//     SUCCESS(longArrayOf(0, 20, 80, 30), intArrayOf(0, 48, 0, 80)),
//     WARNING(longArrayOf(0, 20, 40, 20), intArrayOf(0, 96, 0, 64)),
//     FAILURE(
//         longArrayOf(0, 20, 40, 20, 40, 30, 30, 60),
//         intArrayOf(0, 64, 0, 64, 0, 120, 0, 64)
//     ),
//     INTERACT(longArrayOf(0, 10), intArrayOf(0, 72));
// }

// 진동 세기는 구현이 불가능하여 일단 타이밍만

export const haptic = {
  success: () => {
    if (window.navigator && window.navigator.vibrate) {
      window.navigator.vibrate([20, 80, 30]);
    }
  },
  warning: () => {
    if (window.navigator && window.navigator.vibrate) {
      window.navigator.vibrate([20, 40, 20]);
    }
  },
  failure: () => {
    if (window.navigator && window.navigator.vibrate) {
      window.navigator.vibrate([20, 40, 20, 40, 30, 30, 60]);
    }
  },
  interact: () => {
    if (window.navigator && window.navigator.vibrate) {
      window.navigator.vibrate(10);
    }
  },
};
