import React, { FC, memo, ReactNode } from 'react';

import classNames from 'classnames';
import { get } from 'lodash';

import styles from './Typography.module.scss';

type Variant =
  | 'Headline_1'
  | 'Headline_32'
  | 'Headline_24'
  | 'Headline_24_L'
  | 'Subtitle_20'
  | 'Subtitle_1'
  | 'Subtitle_16'
  | 'Subtitle_14'
  | 'Subtitle_12'
  | 'Subtitle_3'
  | 'Body_1'
  | 'Body_14'
  | 'Caption';

interface Props {
  variant: Variant;
  children?: ReactNode;
  className?: string;
  customTag?: string;
  onClick?: () => void;
}

const Typography: FC<Props> = memo(({ className, variant, children, customTag, onClick }) => {
  const tagMap: { [key in Variant]?: string } = {
    Headline_1: 'h1',
    Headline_24: 'h2',
    Subtitle_1: 'h4',
    Subtitle_16: 'h4',
    Subtitle_3: 'h4',
    Body_1: 'p',
    Body_14: 'p',
  };
  // eslint-disable-next-line react/no-children-prop
  const tag = React.createElement(customTag || tagMap[variant] || 'span', {
    className: classNames(styles.typography, get(styles, variant), className),
    onClick,
    children,
  });
  return <>{tag}</>;
});

export default Typography;
