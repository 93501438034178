import React, { ChangeEvent, TransitionEvent, useEffect, useState } from 'react';

import { AxiosError, AxiosResponse } from 'axios';
import classNames from 'classnames';
import { replace, size } from 'lodash';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';
import { animated, useTransition } from 'react-spring';

import pxios from '../../apis/pxios';
import BottomSheetDialog, {
  BottomSheetDialogButton,
  BottomSheetDialogContent,
  BottomSheetDialogTitle,
} from '../../component-system/BottomSheetDialog/BottomSheetDialog';
import Emoji from '../../component-system/Emoji/Emoji';
import Timer from '../../component-system/Timer/Timer';
import { haptic } from '../../cores/haptic';
import Button from '../../design-system/Button/Button';
import InputField from '../../design-system/Form/InputField/InputField';
import { getTokenFromURLParam } from '../../utils/getTokenFromURLParam';
import { setAuthorization } from '../../utils/setAuthorization';
import { toFormattedInputPhoneNumber } from '../../utils/toFormattedInputPhoneNumber';

import styles from './AuthPhoneNumber.module.scss';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  submitForm?: () => Promise<any>;
  isSubmitLoading: boolean;
}

const AuthPhoneNumber: React.FC<Props> = ({ isOpen, onClose, submitForm, isSubmitLoading }) => {
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [authCode, setAuthCode] = useState<string>('');
  const [verificationCodeValidTime, setVerificationCodeValidTime] = useState(0);
  const [isCodeSend, setCodeSend] = useState(false);
  const [isVisibleCodeInput, setVisibleCodeInput] = useState(false);
  const { hash_id } = useParams<{ hash_id: string }>();
  const token = getTokenFromURLParam();

  const onCloseAuthPhoneNumber = () => {
    onClose();
    // state init
    setPhoneNumber('');
    setAuthCode('');
    setVerificationCodeValidTime(0);
    setCodeSend(false);
    setVisibleCodeInput(false);
  };

  const sendVerificationMutation = useMutation<
    AxiosResponse<{ verification_code_valid_time: number }>,
    AxiosError,
    { phone_number: string }
  >((data) => pxios.post(`pre_inspection/cars/${hash_id}/send_verification/`, data, setAuthorization(token)), {
    onSuccess: (data) => {
      setVerificationCodeValidTime(data.data.verification_code_valid_time);
      setCodeSend(true);
    },
  });

  const verifyMutation = useMutation<AxiosResponse, AxiosError, { verification_code: string }>(
    (data) => pxios.post(`pre_inspection/cars/${hash_id}/verify/`, data, setAuthorization(token)),
    {
      onSuccess: () => {
        if (submitForm) {
          submitForm();
        }
      },
    }
  );

  const submitAuth = async () => {
    if (!authCode) return;
    verifyMutation.mutate({ verification_code: authCode });
    haptic.interact();
  };

  const onClick = () => {
    sendVerificationMutation.mutate({ phone_number: replace(phoneNumber, /-/g, '') });
    haptic.interact();
  };

  const renderTimer = () => {
    return <Timer time={verificationCodeValidTime} onChange={(time) => setVerificationCodeValidTime(time)} />;
  };

  const onChangePhoneNumber = (e: ChangeEvent<HTMLInputElement>) => {
    if (size(replace(e.currentTarget.value, /-/g, '')) > 11) {
      return;
    }
    setPhoneNumber(e.currentTarget.value.replace(/\D/g, ''));
  };

  const onTransitionend = (e: TransitionEvent<HTMLDivElement>) => {
    if (isCodeSend) setVisibleCodeInput(true);
  };

  const onChangeCodeInput = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value.replace(/\D/g, '');
    if (size(value) <= 4) {
      setAuthCode(e.currentTarget.value.replace(/\D/g, ''));
    }
  };

  const transitions = useTransition(isVisibleCodeInput, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  return (
    <BottomSheetDialog isOpen={isOpen} onClose={onCloseAuthPhoneNumber} enableCloseOnOutsideClick={false}>
      <div className={classNames(styles.wrapper, { [styles.isCodeSend]: isCodeSend })} onTransitionEndCapture={onTransitionend}>
        <BottomSheetDialogTitle>
          휴대폰 번호를 인증하면,
          <br />
          신청이 완료돼요.
        </BottomSheetDialogTitle>
        <BottomSheetDialogContent className={styles.content}>
          <Emoji name={'white-up-pointing-index'} className={styles.emoji} />
          평가사가 방문 1시간 전 확인 전화드려요.
        </BottomSheetDialogContent>
        <div className={styles.inputs}>
          <InputField
            size={'big'}
            value={toFormattedInputPhoneNumber(phoneNumber)}
            onChange={onChangePhoneNumber}
            placeholder={'010-0000-0000'}
            rightAddon={
              <Button
                variant="text-brandcolor"
                text={isCodeSend ? '재전송' : '인증요청'}
                className={styles.inputButton}
                onClick={onClick}
                disabled={(isCodeSend && verificationCodeValidTime !== 0) || size(replace(phoneNumber, /-/g, '')) !== 11}
              />
            }
            type={'tel'}
          />
          {transitions(
            (props, item) =>
              item && (
                <animated.div style={props}>
                  <InputField
                    className={classNames(styles.codeInput, { [styles.isVisibleCodeInput]: isVisibleCodeInput })}
                    size={'big'}
                    value={authCode}
                    onChange={onChangeCodeInput}
                    placeholder="인증번호 4자리"
                    disabled={!isCodeSend}
                    rightAddon={renderTimer()}
                  />
                </animated.div>
              )
          )}
        </div>
        <BottomSheetDialogButton
          isLoading={isSubmitLoading}
          onClick={submitAuth}
          text={'입력완료'}
          disabled={!(size(authCode) === 4)}
          type="button"
        />
      </div>
    </BottomSheetDialog>
  );
};

export default AuthPhoneNumber;
