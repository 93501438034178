import React, { useEffect } from 'react';

import { get } from 'lodash';
import { DateTime } from 'luxon';
import Div100vh from 'react-div-100vh';
import { useParams } from 'react-router-dom';

import { ReactComponent as Download } from 'design-system/Icon/download.svg';

import Emoji from '../../component-system/Emoji/Emoji';
import Layout from '../../components/Layout/Layout';
import Button from '../../design-system/Button/Button';
import Typography from '../../design-system/Typography/Typography';
import { useCar } from '../../hooks/api/useCar';
import useChatting from '../../hooks/useChatting';
import { getTokenFromURLParam } from '../../utils/getTokenFromURLParam';
import { isAmPm } from '../../utils/isAmPm';

import styles from './SubmitDone.module.scss';

interface Props {}

const SubmitDone: React.FC<Props> = () => {
  const { hash_id } = useParams<{ hash_id: string }>();
  const { data } = useCar(hash_id, getTokenFromURLParam());
  const preInspection = get(data, 'pre_inspection');
  const installLink = get(data, 'install_link');
  const reserveAt = get(preInspection, 'reserve_at');
  const reserveAtDateTime = reserveAt ? DateTime.fromFormat(reserveAt, 'yyyy-MM-dd HH:mm:ss') : null;
  const address = get(preInspection, 'address');

  useChatting(hash_id, data?.car_number || '', data?.is_chat_available || false);

  useEffect(() => {
    history.pushState(null, 'Home', '/');
    window.onpopstate = function () {
      history.go(1);
    };
  }, []);

  return (
    <Div100vh>
      <Layout className={styles.layout}>
        <section className={styles.result}>
          <svg xmlns="http://www.w3.org/2000/svg" className={styles.check} width="126" height="190" viewBox="0 0 126 190">
            <path d="M0.5 96l82.4 54.1L165.6 0.3" />
          </svg>
          <Typography variant={'Headline_24'}>접수 완료</Typography>
          <Typography className={styles.content} variant={'Body_14'}>
            예약이 확정되면 알림톡으로 안내드릴게요
          </Typography>
          <Typography variant={'Body_14'}>
            <Emoji name={'two-o-clock'} className={styles.emoji} />
            {reserveAtDateTime
              ? reserveAtDateTime
                  .toFormat(`yyyy-MM-dd EEEE ${isAmPm(reserveAtDateTime) === 'am' ? '오전' : '오후'} h시 m분 방문예정`)
                  .replace(' 0분', '')
              : ''}
            <br />
            <Emoji name={'white-flag'} className={styles.emoji} />
            {address}
          </Typography>
        </section>
        <section className={styles.installApp}>
          <Typography variant={'Subtitle_16'}>
            지금 앱을 설치하면,
            <br />
            진행상황을 더 쉽고 편하게 볼 수 있어요.
          </Typography>
          <div className={styles.imageWrapper}>
            <div className={styles.mockupInstallApp} />
            <Button
              className={styles.button}
              text={'앱 설치하고 진행상황 보기'}
              leftIcon={<Download className={styles.icon} />}
              href={installLink}
            />
          </div>
        </section>
      </Layout>
    </Div100vh>
  );
};

export default SubmitDone;
