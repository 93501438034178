import React from 'react';

import { useHistory } from 'react-router-dom';

import { ReactComponent as ChatIcon } from 'images/ic_chat_white.svg';

import Button from '../../../design-system/Button/Button';
import Layer from '../../../design-system/Layer/Layer';
import Typography from '../../../design-system/Typography/Typography';
import Modal from '../../Modal/Modal';

import styles from './DiplomacyPopup.module.scss';

interface Props {
  isVisible: boolean;
  onClose: () => void;
}
const DiplomacyPopup: React.FC<Props> = ({ isVisible, onClose }) => {
  return (
    <Modal isVisible={isVisible} onClose={onClose} className={styles.diplomacyPopup}>
      <Layer variant={'level_8'}>
        <Typography variant="Body_14" className={styles.content}>
          ⓘ 외교 차량은 판매가 어렵습니다. <br />
          헤이딜러 앱을 다운받으셔서 채팅문의 부탁드립니다.
        </Typography>
        <footer className={styles.footer}>
          <Button text="취소" variant="text-brandcolor" onClick={onClose} className={styles.cancelButton} />
        </footer>
      </Layer>
    </Modal>
  );
};

export default DiplomacyPopup;
