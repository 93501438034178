import React, { ButtonHTMLAttributes, DetailedHTMLProps, useEffect, useRef, useState } from 'react';

import {
  disableBodyScroll as bodyScrollLockDisableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from 'body-scroll-lock';
import classNames from 'classnames';
import OutsideClickHandler from 'react-outside-click-handler';
import { Portal } from 'react-portal';

import Button, { Props as ButtonProps } from 'design-system/Button/Button';

import { ReactComponent as IcClose } from '../../design-system/Icon/close.svg';
import Typography from '../../design-system/Typography/Typography';
import { useMeasurePolyfill } from '../../hooks/useMeasurePolyfill';

import styles from './BottomSheetDialog.module.scss';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  enableCloseButton?: boolean;
  enablePopupAnimation?: boolean;
  enableCloseOnOutsideClick?: boolean;
  disableBodyScroll?: boolean;
  contentClass?: string;
  height?: number;
}

// DESC usage
//  height를 bounds로 계산하기 때문에
//  margin 안 먹습니다
const BottomSheetDialog: React.FC<Props> = ({
  children,
  isOpen,
  onClose,
  enableCloseButton = true,
  enablePopupAnimation = true,
  enableCloseOnOutsideClick = true,
  disableBodyScroll = true,
  contentClass,
  height = null,
}) => {
  const [ref, bounds] = useMeasurePolyfill();
  const scrollEnableTargetRef = useRef<HTMLDivElement>(null);

  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    if (scrollEnableTargetRef.current && disableBodyScroll) {
      if (isOpen) {
        setScrollPosition(window.pageYOffset);
        bodyScrollLockDisableBodyScroll(scrollEnableTargetRef.current);
      } else {
        enableBodyScroll(scrollEnableTargetRef.current);
        window.scrollTo(0, scrollPosition);
      }
      return () => {
        clearAllBodyScrollLocks();
      };
    }
  }, [isOpen]);

  return (
    <Portal>
      <div
        className={classNames(styles.dimmer, isOpen ? styles.open : styles.close, !enablePopupAnimation && styles.noTransition)}
      >
        <OutsideClickHandler onOutsideClick={enableCloseOnOutsideClick ? onClose : () => null}>
          <div
            className={classNames(styles.wrapper, !enablePopupAnimation && styles.noTransition)}
            style={{ height: isOpen ? height || bounds.height : 0, opacity: isOpen ? 1 : 0 }}
            ref={scrollEnableTargetRef}
          >
            <div ref={ref} className={classNames(contentClass)}>
              {children}
            </div>
            {enableCloseButton && (
              <span className={styles.closeIconWrap} onClick={onClose}>
                <IcClose className={styles.closeIcon} />
              </span>
            )}
          </div>
        </OutsideClickHandler>
      </div>
    </Portal>
  );
};

export const BottomSheetDialogTitle: React.FC<{ className?: string }> = ({ children, className }) => (
  <Typography variant={'Subtitle_20'} className={className}>
    {children}
  </Typography>
);

// TODO 린트가 싫어해서 주석처리
//  쓸 때 주석 풀고 꺼내 쓰면 됩니다
// export const BottomSheetDialogSubtitle: React.FC<{ className?: string }> = ({ children }) => (
//   <Typography variant={'Subtitle_16'}>{children}</Typography>
// );

export const BottomSheetDialogContent: React.FC<{ className?: string }> = ({ children, className }) => (
  <Typography variant={'Body_14'} className={classNames(styles.content, className)}>
    {children}
  </Typography>
);

export const BottomSheetDialogCaption: React.FC<{ className?: string }> = ({ children, className }) => (
  <Typography variant={'Caption'} className={classNames(styles.caption, className)}>
    {children}
  </Typography>
);

export const BottomSheetDialogButton: React.FC<
  ButtonProps & DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>
> = ({ ...otherProps }) => <Button className={styles.button} {...otherProps} variant={'primary-brandcolor'} />;

export default BottomSheetDialog;
