import { useEffect, useState } from 'react';

export const useLandscape = () => {
  const [isLandscape, setLandscape] = useState(false);
  const resize = () => {
    if (window.innerHeight < 640 && window.innerWidth > window.innerHeight) {
      setLandscape(true);
    } else {
      setLandscape(false);
    }
  };
  useEffect(() => {
    resize();
    window.addEventListener('resize', resize);
    return () => {
      window.removeEventListener('resize', resize);
    };
  }, []);

  return isLandscape;
};
