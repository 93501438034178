import React, { useCallback, useState } from 'react';

import { AxiosError, AxiosResponse } from 'axios';
import classNames from 'classnames';
import { trim } from 'lodash';
import { useMutation, useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';

import Loading from 'images/gifs/loading.gif';

import pxios from '../../apis/pxios';
import {
  BottomSheetDialogButton,
  BottomSheetDialogCaption,
  BottomSheetDialogContent,
  BottomSheetDialogTitle,
} from '../../component-system/BottomSheetDialog/BottomSheetDialog';
import Emoji from '../../component-system/Emoji/Emoji';
import { haptic } from '../../cores/haptic';
import InputField from '../../design-system/Form/InputField/InputField';
import { useCar } from '../../hooks/api/useCar';
import { HashIdAndToken } from '../../pages/Home';
import { setAuthorization } from '../../utils/setAuthorization';

import CarInfoSummary from './CarInfoSummary/CarInfoSummary';

import styles from './CarInfoLoader.module.scss';

interface Props {
  carNumber?: string;
  hashIdAndToken: HashIdAndToken;
}

const CarInfoLoader: React.FC<Props> = ({ carNumber, hashIdAndToken }) => {
  const [name, setName] = useState<string>('');
  const [isPassed, setPassed] = useState(true);
  const { hashId, token } = hashIdAndToken;
  const history = useHistory();
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation<AxiosResponse<{ is_passed: boolean }>, AxiosError, { owner_name: string }>(
    (data) => pxios.post(`pre_inspection/cars/${hashId}/check_owner_name/`, data, setAuthorization(token)),
    {
      onSuccess: async (data) => {
        const isPassed = data.data.is_passed;
        if (isPassed) {
          await queryClient.invalidateQueries(`pre_inspection/cars/${hashId}/`);
          history.push(`/cars/${hashId}/result`);
          haptic.success();
        }
        setPassed(data.data.is_passed);
      },
    }
  );

  const { data } = useCar(hashId, token);

  if (data?.current_step === 'unavailable') {
    queryClient.invalidateQueries(`pre_inspection/cars/${hashId}/`);
    history.push(`/cars/${hashId}/result`);
  }

  const isCarLoading = !data;

  const handleSubmitName = () => {
    mutate({ owner_name: name });
    haptic.interact();
  };

  const handleNameChange: React.FormEventHandler<HTMLInputElement> = (e) => {
    const value = e.currentTarget.value;
    setName(value);
  };

  const loadingPlaceholder = useCallback(
    (isHidden?: boolean) => (
      <div className={classNames(styles.carInfoLoader, isHidden && styles.hidden, styles.loadingWrapper)}>
        <BottomSheetDialogTitle>
          차량정보를
          <br />
          불러오고 있어요.
        </BottomSheetDialogTitle>
        <div className={styles.loaderCarNumberGIF}>
          <img src={Loading} alt={carNumber} className={styles.gif} />
        </div>
      </div>
    ),
    []
  );

  if (isCarLoading) {
    return loadingPlaceholder();
  }

  return (
    <>
      <div className={styles.carInfoLoader}>
        <BottomSheetDialogTitle className={styles.title}>
          {isPassed ? (
            <>
              자동차등록증 상
              <br />
              소유자명을 알려주세요 <Emoji name={'white-up-pointing-index'} className={styles.emoji} />
            </>
          ) : (
            <>
              소유자명이 틀렸다고 하네요.
              <br />
              다시 확인해주세요 <Emoji name={'white-up-pointing-index'} className={styles.emoji} />
            </>
          )}
        </BottomSheetDialogTitle>
        <BottomSheetDialogContent>공동명의는 대표자 1명 이름만 입력하면 돼요.</BottomSheetDialogContent>
        <div className={styles.carInfoContainer}>
          {data && <CarInfoSummary data={data} />}
          <InputField
            warning={!isPassed}
            className={styles.nameInput}
            placeholder={'홍길동'}
            value={name}
            size={'big'}
            onChange={handleNameChange}
          />
        </div>
        <BottomSheetDialogCaption>부정사용 방지를 위해, 소유자가 일치해야 진행 가능해요.</BottomSheetDialogCaption>
        <BottomSheetDialogButton text={'입력완료'} disabled={!trim(name)} isLoading={isLoading} onClick={handleSubmitName} />
      </div>
      {loadingPlaceholder(true)}
    </>
  );
};

export default CarInfoLoader;
