import React from 'react';

import classNames from 'classnames';
import { useInView } from 'react-intersection-observer';

import { ReactComponent as ICPass } from 'images/ic_pass.svg';

import Typography from '../../../design-system/Typography/Typography';

import styles from './Section5.module.scss';

const Section5: React.FC = () => {
  const [section5Ref, section5InView] = useInView({
    threshold: 0.6,
    triggerOnce: true,
  });
  return (
    <div className={classNames(styles.section5, { [styles.view]: section5InView })} ref={section5Ref}>
      <Typography variant="Headline_1">🤔</Typography>
      <Typography variant="Subtitle_1">
        헤이딜러 <span className={styles.zero}>제로경매</span>, <br />
        일반경매와 어떻게 다를까요?
      </Typography>
      <div className={styles.content}>
        <div className={styles.left}>
          <Typography variant="Subtitle_16" className={styles.column}>
            일반경매
          </Typography>
          <Typography variant="Body_14" className={styles.column}>
            셀프 업로드
          </Typography>
          <Typography variant="Body_14" className={styles.column}>
            딜러 직접 만남
          </Typography>
          <Typography variant="Body_14" className={styles.column}>
            현장감가 가능성 있음
          </Typography>
          <Typography variant="Body_14" className={styles.column}>
            무료
          </Typography>
        </div>
        <div className={styles.right}>
          <Typography variant="Subtitle_16" className={classNames(styles.column, styles.zero)}>
            제로경매
          </Typography>
          <Typography variant="Body_14" className={styles.column}>
            <ICPass className={styles.icPass} /> 전문평가사 진단
          </Typography>
          <Typography variant="Body_14" className={styles.column}>
            <ICPass className={styles.icPass} />
            딜러만남 없음
          </Typography>
          <Typography variant="Body_14" className={styles.column}>
            <ICPass className={styles.icPass} />
            현장감가 없음
          </Typography>
          <Typography variant="Body_14" className={styles.column}>
            <span className={styles.originalPrice}>29,000원</span> <span className={styles.free}>무료</span>
          </Typography>
        </div>
      </div>
      <Typography variant="Body_14" className={styles.info}>
        * 일반경매는 헤이딜러 앱에서만 이용가능해요.
      </Typography>
    </div>
  );
};

export default Section5;
