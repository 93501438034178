import { uuid } from 'uuidv4';

/**
 * 고객이 무분별하게 차량을 조회하는 경우를 대응하기 위해 횟수 제한을 두는데 사용.
 * 웹은 고객 식별이 불가능해서 uuid를 이용해서 식별하는 것이 목적.
 *
 * 다른 브라우저를 이용하면 uuid는 초기화 되지 않는가?
 * ip제한도 같이 추가.
 * ip 제한만 추가하면 공동 와이파이를 쓰는 회사 같은 곳에 있는 경우 너무 쉽게 막혀버려
 * ip제한은 조금 여유롭게 주고, 웹 uuid 제한을 조금 빡빡하게함
 *
 * **/
export const getUUID = () => {
  const previousUUID = localStorage.getItem('uuid');
  if (previousUUID) {
    return previousUUID;
  } else {
    const newUUID = uuid();
    localStorage.setItem('uuid', newUUID);
    return newUUID;
  }
};
