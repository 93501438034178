import React, { forwardRef } from 'react';

import classNames from 'classnames';
import { useInView } from 'react-intersection-observer';

import Typography from '../../../design-system/Typography/Typography';

import styles from './Section2.module.scss';

interface Props {
  section2InView: boolean;
}
const Section2: React.ForwardRefRenderFunction<HTMLDivElement, Props> = ({ section2InView }, ref) => {
  return (
    <div className={classNames(styles.section2)}>
      <div className={styles.description}>
        <Typography variant="Body_1" className={styles.subTitle}>
          🔍 경매 등록을 직접할 필요 없어요.
        </Typography>
        <Typography variant="Body_1" className={styles.subTitle}>
          💰 경매 최고가에서 감가가 없어요.
        </Typography>
        <Typography variant="Body_1" className={styles.subTitle}>
          👌 딜러만남이 전혀 없어요.
        </Typography>
      </div>
      <div className={classNames(styles.freeInfo, { [styles.view]: section2InView })} ref={ref}>
        <Typography variant="Headline_1" className={styles.emoji}>
          🎉
        </Typography>
        <Typography variant="Subtitle_1">
          제로경매 런칭기념 <br />
          이용료 무료
        </Typography>
        <div className={styles.priceWrapper}>
          <span className={styles.originalPrice}>29,000원</span>
          <div className={styles.salePriceWrapper}>
            <Typography variant="Headline_1" className={styles.salePrice}>
              0
            </Typography>
            <Typography variant="Headline_32" className={styles.salePrice}>
              원
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

export default forwardRef(Section2);
