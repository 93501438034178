import React from 'react';

import classNames from 'classnames';
import { map } from 'lodash';
import { useInView } from 'react-intersection-observer';

import { ReactComponent as SmallLogo } from 'images/small-logo.svg';

import { appDownLink } from '../../../config';
import Button from '../../../design-system/Button/Button';
import Typography from '../../../design-system/Typography/Typography';
import Ripple from '../../Ripple/Ripple';

import styles from './Section6.module.scss';

const faqs = [
  {
    emoji: '😶',
    question: '기존 헤이딜러 경매 서비스는\n' + '어떻게 이용하나요?',
    answer: '헤이딜러 앱에서 이용 가능해요.\n' + "앱스토어에서 ‘헤이딜러'를 다운받아주세요.",
  },
  {
    emoji: '🤔',
    question: '전문평가사가 직접 가격을 알려주나요?',
    answer:
      '아닙니다. 평가사는 꼼꼼한 차량진단과 경매 업로드만 담당합니다.  \n' + '평가사가 직접 견적을 내거나 매입하지 않습니다.',
  },
  {
    emoji: '😲',
    question: '그럼, 내 차 경매에 누가 참여하나요?',
    answer:
      '전국 중고차 매입딜러가 참여합니다.\n' + '전국 딜러 90%가 사용하기 때문에, 치열한 경쟁입찰로 좋은 가격을 받을 수 있어요.',
  },
];
const Section6: React.FC = () => {
  const [section6Ref, section6InView] = useInView({
    threshold: 0.2,
    triggerOnce: true,
  });

  const onClickDownload = () => {
    window.open(appDownLink);
  };
  return (
    <div className={classNames(styles.section6, { [styles.view]: section6InView })} ref={section6Ref}>
      {map(faqs, (faq, index) => (
        <div className={styles.faq} key={index}>
          <Typography variant="Headline_1">{faq.emoji}</Typography>
          <Typography variant="Subtitle_16" className={styles.question}>
            {faq.question}
          </Typography>
          <Typography variant="Body_1" className={styles.answer}>
            {faq.answer}
          </Typography>
          {index === 0 && (
            <div className={styles.buttonWrapper}>
              <Ripple />
              <Button
                leftIcon={<SmallLogo className={styles.smallLogo} />}
                variant="secondary-black"
                text={'앱 다운로드'}
                size="medium"
                onClick={onClickDownload}
              />
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default Section6;
