import { AxiosError, AxiosResponse } from 'axios';
import { useMutation } from 'react-query';

import pxios from '../../apis/pxios';
import { setAuthorization } from '../../utils/setAuthorization';

interface LogMutationInput {
  car: string;
  type: 'web_pre_inspection_available' | 'web_pre_inspection_reserve';
}
export const useLogMutation = (token: string | null) => {
  const mutation = useMutation<AxiosResponse<{ is_passed: boolean }>, AxiosError, LogMutationInput>((data) =>
    pxios.post(`log/customer_action/`, data, setAuthorization(token))
  );

  return mutation;
};
