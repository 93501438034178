import React from 'react';

import classNames from 'classnames';

import Typography from '../../design-system/Typography/Typography';
import Ripple from '../Ripple/Ripple';

import styles from './Footer.module.scss';
const Footer: React.FC = () => {
  const onClickPrivacyPolicy = () => {
    window.open('https://api.heydealer.com/posts/ERJ1ZakN/');
  };

  return (
    <div className={styles.footer}>
      <Typography variant="Subtitle_3" className={styles.info}>
        (주)피알앤디컴퍼니
      </Typography>
      <div className={styles.content}>
        <Typography variant="Body_14" className={classNames(styles.privacyPolicy, styles.info)} onClick={onClickPrivacyPolicy}>
          서비스 이용약관 I 개인정보 처리방침
        </Typography>
        <Typography variant="Body_14" className={styles.info}>
          2021 ⓒ PRND all rights reserved.
        </Typography>
      </div>
    </div>
  );
};

export default Footer;
