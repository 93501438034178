import React from 'react';

import detailImage from 'images/detail_image.png';

import { ReactComponent as ConfirmPass } from 'design-system/Icon/confirm_pass.svg';

import BottomSheetDialog from '../../component-system/BottomSheetDialog/BottomSheetDialog';
import Emoji from '../../component-system/Emoji/Emoji';
import Typography from '../../design-system/Typography/Typography';
import Footer from '../Footer/Footer';
import Section3 from '../home/Section3/Section3';

import styles from './AfterProgressPopup.module.scss';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}
const AfterProgressPopup: React.FC<Props> = ({ isOpen, onClose }) => {
  return (
    <BottomSheetDialog
      isOpen={isOpen}
      onClose={onClose}
      enableCloseButton={true}
      contentClass={styles.popupContent}
      disableBodyScroll={false}
    >
      <section className={styles.intro}>
        <div className={styles.titleWrapper}>
          <Typography variant="Subtitle_16">이후 진행과정</Typography>
        </div>
      </section>
      <div className={styles.progress}>
        <Section3 animationEnable={false} />
        <Footer />
      </div>
    </BottomSheetDialog>
  );
};

export default AfterProgressPopup;
