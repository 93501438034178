import axios, { AxiosPromise, AxiosRequestConfig } from 'axios';
import { useLocation } from 'react-router-dom';
import { Action } from 'redux';
import { ThunkAction, ThunkDispatch, ThunkMiddleware } from 'redux-thunk';

import { AppDispatch, store } from '../store';
import { createToast } from '../store/toastSlice';

// import { store } from '../containers/Root/Root';
// import { getAuthorizationHeader } from '../cores/getAuthorizationHeader';
// import { ToastActions, ToastMessageTypes } from '../store/toast';

export const requester = axios.create({
  baseURL: `${process.env.REACT_APP_HOST}`,
});

export const setAuthorizedRequesterToken = () => {
  // requester.defaults.headers.common['Authorization'] = getAuthorizationHeader().Authorization;
};

const treatPxiosResponse = (promise: AxiosPromise) => {
  return promise
    .then((response) => response)
    .catch((err: any) => {
      const message = err.response.data.toast.message;
      if (message) {
        // Todo thunkAction으로 변경(any 타입 없애기)
        store.dispatch<any>(createToast({ message, variant: 'fail' }));
      }
      throw new Error(err.response.data.toast.message);
    });
};

class Pxios {
  get<T = any>(url: string, config?: AxiosRequestConfig): AxiosPromise<T> {
    return treatPxiosResponse(requester.get(url, config));
  }
  put<T = any>(url: string, data?: any, config?: AxiosRequestConfig): AxiosPromise<T> {
    return treatPxiosResponse(requester.put(url, data, config));
  }
  post<T = any>(url: string, data?: any, config?: AxiosRequestConfig): AxiosPromise<T> {
    return treatPxiosResponse(requester.post(url, data, config));
  }
  delete<T = any>(url: string, config?: AxiosRequestConfig): AxiosPromise<T> {
    return treatPxiosResponse(requester.delete(url, config));
  }
  patch<T = any>(url: string, data?: any, config?: AxiosRequestConfig): AxiosPromise<T> {
    return treatPxiosResponse(requester.patch(url, data, config));
  }
}

const pxios = new Pxios();
export default pxios;
