import React, { FC, forwardRef, ForwardRefRenderFunction } from 'react';

import classNames from 'classnames';
import { map } from 'lodash';
import { useInView } from 'react-intersection-observer';

import zeroGuide1 from 'images/home/zeroGuide1.png';
import zeroGuide2 from 'images/home/zeroGuide2.png';
import zeroGuide3 from 'images/home/zeroGuide3.png';
import zeroGuide4 from 'images/home/zeroGuide4.png';

import Typography from '../../../design-system/Typography/Typography';

import styles from './Section3.module.scss';

interface Props {
  animationEnable?: boolean;
}

const zeroGuideProcesses = [
  {
    title: '방문진단',
    description: '헤이딜러 전문평가사가 내외관, 사고, 옵션을 꼼꼼히 진단하고, 사진찍어 경매에 올려드려요.',
    image: zeroGuide1,
  },
  {
    title: '24시간 실시간 경매',
    description: '내 차를 원하는 딜러들이 24시간 동안 입찰합니다. 평균 30명이 견적을 제시해요.',
    image: zeroGuide2,
  },
  {
    title: '판매여부 결정',
    description: '최고가가 마음에 드나요?\n' + '3일 내 판매를 결정하세요.\n' + '최고가 그대로, 감가없이 거래돼요.',
    image: zeroGuide3,
  },
  {
    title: '에스크로 안심거래',
    description: '낙찰 딜러와 만남없이, 에스크로 송금과 탁송으로 거래돼요. \n' + '모든 과정을 헤이딜러가 책임집니다.',
    image: zeroGuide4,
  },
];

const Section3: FC<Props> = ({ animationEnable = true }) => {
  const [process1Ref, process1InView] = useInView({
    threshold: 0.6,
    triggerOnce: true,
  });
  const [process2Ref, process2InView] = useInView({
    threshold: 0.6,
    triggerOnce: true,
  });
  const [process3Ref, process3InView] = useInView({
    threshold: 0.6,
    triggerOnce: true,
  });
  const [process4Ref, process4InView] = useInView({
    threshold: 0.6,
    triggerOnce: true,
  });

  const refs = [process1Ref, process2Ref, process3Ref, process4Ref];
  const processInViews = [process1InView, process2InView, process3InView, process4InView];

  return (
    <div className={styles.section3}>
      <Typography variant="Subtitle_1" className={styles.title}>
        헤이딜러 제로경매는 <br />
        이렇게 진행돼요.
      </Typography>
      {map(zeroGuideProcesses, (process, index) => {
        return (
          <div
            key={index}
            className={classNames(styles.process, {
              [styles.view]: processInViews[index],
              [styles.animationDisable]: !animationEnable,
            })}
            ref={refs[index]}
          >
            <div className={styles.subTitle}>
              <span className={styles.order}>{index + 1}</span>
              <Typography variant="Subtitle_16">{process.title}</Typography>
            </div>
            <Typography variant="Body_1" className={styles.description}>
              {process.description}
            </Typography>
            <div className={styles.imageWrapper}>
              <img src={process.image} alt={process.title} className={styles.processImg} />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Section3;
