import React, { useCallback, useState } from 'react';

import classNames from 'classnames';
import { chain, map, range, forEach } from 'lodash';
import { DateTime } from 'luxon';

import { ReactComponent as ArrowLeft } from 'design-system/Icon/arrow_left.svg';
import { ReactComponent as ArrowRight } from 'design-system/Icon/arrow_right.svg';

import { PreInspectionCalendar } from '../../../../declaration/car';
import Typography from '../../../../design-system/Typography/Typography';

import styles from './Calendar.module.scss';

const weekdays = ['일', '월', '화', '수', '목', '금', '토'];

interface Props {
  onClickDay: (dateTime: DateTime) => void;
  preInspectionCalendars?: PreInspectionCalendar[];
  selectedDate: DateTime | null;
}

const Calendar: React.FC<Props> = ({ onClickDay, preInspectionCalendars, selectedDate }) => {
  const preInspectionDateTimes = map(preInspectionCalendars, (preInspectionCalendar) =>
    DateTime.fromFormat(preInspectionCalendar.date, 'yyyy-MM-dd HH:mm:ss')
  );
  const value = null;
  const parsedDateTime = value
    ? DateTime.fromFormat(value, 'yyyy-MM-dd HH:mm:ss').set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0,
      })
    : DateTime.local().set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0,
      });

  const [addMonth, setAddMonth] = useState(0);
  const standardDateTime = parsedDateTime.plus({ month: addMonth });
  const startDay = standardDateTime.set({ day: 1 }).weekday;
  const startIndex = startDay === 7 ? 1 : -startDay;

  const getDates = () => {
    return chain(range(1, 36))
      .map((index) => standardDateTime.set({ day: index + startIndex }))
      .value();
  };

  const isToday = (dateTime: DateTime) => {
    return dateTime.hasSame(DateTime.local(), 'day');
  };

  const getAvailable = (dateTime: DateTime) => {
    let isAvailable = false;
    forEach(preInspectionDateTimes, (preInspectionDateTime) => {
      if (preInspectionDateTime.hasSame(dateTime, 'day')) {
        isAvailable = true;
      }
    });
    return isAvailable;
  };

  const decrement = useCallback(() => {
    setAddMonth((prevAddMonth) => prevAddMonth - 1);
  }, []);

  const increment = useCallback(() => {
    setAddMonth((prevAddMonth) => prevAddMonth + 1);
  }, []);

  const renderToday = (day: number) => {
    return (
      <span className={styles.today}>
        <span>{day}</span>
        <span className={styles.todayText}>오늘</span>
      </span>
    );
  };

  const onClick = (date: DateTime, isAvailable: boolean) => {
    if (!isAvailable) {
      return;
    }
    onClickDay(date);
  };

  return (
    <div className={styles.calendar}>
      <div className={styles.header}>
        <span className={styles.iconWrapper} onClick={decrement}>
          <ArrowLeft className={styles.arrowIcon} />
        </span>
        <Typography variant="Body_1" className={styles.month}>
          {standardDateTime.toFormat('yyyy.MM')}
        </Typography>
        <span className={styles.iconWrapper} onClick={increment}>
          <ArrowRight className={styles.arrowIcon} />
        </span>
      </div>

      <div className={styles.weekdays}>
        {map(weekdays, (weekday) => (
          <span key={weekday} className={styles.weekday}>
            {weekday}
          </span>
        ))}
      </div>
      <div className={styles.days}>
        {map(getDates(), (date) => {
          const isAvailable = getAvailable(date);
          return (
            <span
              key={date.toString()}
              className={classNames(styles.day, {
                [styles.isToday]: isToday(date),
                [styles.isSelected]: selectedDate?.hasSame(date, 'day'),
                [styles.disable]: !isAvailable,
              })}
              onClick={() => onClick(date, isAvailable)}
            >
              {isToday(date) ? renderToday(date.day) : <span>{date.day}</span>}
            </span>
          );
        })}
      </div>
    </div>
  );
};

export default Calendar;
