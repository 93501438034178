import React, { useEffect } from 'react';

import classNames from 'classnames';
import { map } from 'lodash';
import { Swiper } from 'swiper';

import reviewImg1 from 'images/home/reviewImage1.png';
import reviewImg2 from 'images/home/reviewImage2.png';
import reviewImg3 from 'images/home/reviewImage3.png';
import reviewLogo from 'images/home/reviewLogo.png';

import Typography from '../../../../design-system/Typography/Typography';

import styles from './ReviewCarousel.module.scss';

const reviewCards = [
  {
    image: reviewImg1,
    description: '헤이딜러 전문평가사가 올린 정보로 경매가 진행된다.\n사전에 검수했기 때문에 절대 추가 네고는 없다.',
    car: 'G70 2.0T',
    url: 'https://blog.naver.com/sakl25/222471241795',
    logo: reviewLogo,
  },
  {
    image: reviewImg2,
    description: '헤이딜러 ZERO 장점은 딜러를 직접 만날 필요가 없는 점!\n경매 최고가를 보고 탁송으로 팔기만 누르면 됩니다.',
    car: '로체 이노베이션 LEX20',
    url: 'https://blog.naver.com/refrain/222505162734',
    logo: reviewLogo,
  },
  {
    image: reviewImg3,
    description:
      '너무나 친절한 헤이딜러 평가사가 자세히 설명해주고, 경매에 올려준다. 더 궁금한 점은 헤이딜러 채팅에 물어보면 된다.',
    car: '말리부  2.0 LT',
    url: 'https://blog.naver.com/solhee857/222498217608',
    logo: reviewLogo,
  },
];
const ReviewCarousel: React.FC = () => {
  useEffect(() => {
    new Swiper('.swiper-container', {
      spaceBetween: 16,
    });
  }, []);

  return (
    <div className={styles.carousel}>
      <div className="swiper-container">
        <div className="swiper-wrapper">
          {map(reviewCards, (card, index) => (
            <div className={classNames(styles.card, styles.slide, 'swiper-slide')} key={index}>
              <a href={card.url} target="_blank">
                <img className={styles.reviewImage} src={card.image} alt="차량 정검" />
                <Typography variant="Body_14" className={styles.description}>
                  {card.description}
                </Typography>
                <span className={styles.car}>{card.car}</span>
                <div className={styles.footer}>
                  <img className={styles.reviewLogo} src={card.logo} alt="차량 정검" />
                </div>
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ReviewCarousel;
