import React from 'react';

import classNames from 'classnames';

import styles from './Layer.module.scss';

interface Props {
  variant: 'level_8';
  className?: string;
}
const Layer: React.FC<Props> = ({ children, variant, className }) => {
  return <div className={classNames(styles[variant], className)}>{children}</div>;
};

export default Layer;
