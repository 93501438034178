import React, { useEffect, useState } from 'react';

import classNames from 'classnames';
import { DateTime } from 'luxon';

import { haptic } from '../../cores/haptic';
import { ReactComponent as Timer } from '../../design-system/Icon/timer.svg';
import Typography from '../../design-system/Typography/Typography';
import { useToast } from '../../hooks/useToast';
import { isAmPm } from '../../utils/isAmPm';
import Ripple from '../Ripple/Ripple';

import ReservationModal from './ReservationModal/ReservationModal';

import styles from './ReservationInput.module.scss';

interface Props {
  rawAddress: PostcodeData | null;
  reserveAt?: string;
  onChangeReserveAt: (reserveAt: string) => void;
}
const ReservationInput: React.FC<Props> = ({ rawAddress, reserveAt, onChangeReserveAt }) => {
  const [openPostCodeModal, setPostCodeModal] = useState(false);
  const reserveAtDateTime = reserveAt ? DateTime.fromFormat(reserveAt, 'yyyy-MM-dd HH:mm:ss') : null;
  const [disable, setDisable] = useState(false);
  const [isFocus, setFocus] = useState(false);
  const toast = useToast();
  const handleAddressInput = () => {
    if (disable) {
      toast.fail('방문진단 받으실 지역을 먼저 선택해주세요.');
      return;
    }
    setPostCodeModal(true);
    haptic.interact();
  };

  useEffect(() => {
    if (!rawAddress) {
      setDisable(true);
    }
  }, [rawAddress]);

  return (
    <>
      <input
        type="text"
        className={styles.hiddenInput}
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
        id="reservation-input"
        readOnly={true}
      />
      <label
        className={classNames(styles.input, { [styles.disable]: disable, [styles.focus]: isFocus })}
        onClick={handleAddressInput}
        htmlFor="reservation-input"
      >
        <Ripple />
        <Timer className={styles.icon} />
        {reserveAtDateTime ? (
          <Typography variant="Body_14">
            {reserveAtDateTime
              .toFormat(`yyyy-MM-dd EEEE ${isAmPm(reserveAtDateTime) === 'am' ? '오전' : '오후'} h시 m분`)
              .replace(' 0분', '')}
          </Typography>
        ) : (
          <Typography variant="Body_14" className={styles.placeholder}>
            언제 방문드릴까요?
          </Typography>
        )}
      </label>
      <ReservationModal
        isVisible={openPostCodeModal}
        onClose={() => setPostCodeModal(false)}
        rawAddress={rawAddress}
        reserveAt={reserveAt}
        onChangeReserveAt={onChangeReserveAt}
        setDisable={setDisable}
        setFocus={setFocus}
      />
    </>
  );
};

export default ReservationInput;
