import React from 'react';

import classNames from 'classnames';
import { get } from 'lodash';

import sportUtilityVehicle from 'images/emojis/sport-utility-vehicle.png';

import backhandIndexPointingDown from '../../images/emojis/backhand-index-pointing-down.png';
import pageFacingUp from '../../images/emojis/page-facing-up.png';
import triangularFlag from '../../images/emojis/triangular-flag.png';
import twoOClock from '../../images/emojis/two-o-clock.png';
import whiteFlag from '../../images/emojis/white-flag.png';
import whiteUpPointingIndex from '../../images/emojis/white-up-pointing-index.png';

import styles from './Emoji.module.scss';

export type EmojiVariants =
  | 'backhand-index-pointing-down'
  | 'white-up-pointing-index'
  | 'two-o-clock'
  | 'sport-utility-vehicle'
  | 'white-flag'
  | 'page-facing-up'
  | 'triangular-flag';

const variants: { [key in EmojiVariants]: string } = {
  'backhand-index-pointing-down': backhandIndexPointingDown,
  'white-up-pointing-index': whiteUpPointingIndex,
  'two-o-clock': twoOClock,
  'sport-utility-vehicle': sportUtilityVehicle,
  'white-flag': whiteFlag,
  'page-facing-up': pageFacingUp,
  'triangular-flag': triangularFlag,
};

interface Props {
  size?: number;
  name: EmojiVariants;
  className?: string;
}
const Emoji: React.FC<Props> = ({ size, name, className }) => {
  return (
    <span
      className={classNames(styles.emoji, className)}
      style={{ width: size, height: size, background: `url(${get(variants, name)}) no-repeat center` }}
    />
  );
};

export default Emoji;
