import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { uniqueId } from 'lodash';

import { AppDispatch } from './index';

export type ToastVariant = 'default' | 'success' | 'fail';

interface Toast {
  id: string;
  message: string;
  variant: ToastVariant;
}
export interface ToastState {
  toasts: Toast[];
}

const initialState: ToastState = {
  toasts: [],
};

export const toastSlice = createSlice({
  name: 'toast',
  initialState,
  reducers: {
    startToast: (state, action: PayloadAction<{ id: string; message: string; variant: ToastVariant }>) => {
      state.toasts.push({
        id: action.payload.id,
        message: action.payload.message,
        variant: action.payload.variant,
      });
    },
    endToast: (state, action: PayloadAction<{ id: string }>) => {
      const findToastIndex = state.toasts.findIndex((toast) => toast.id === action.payload.id);
      if (findToastIndex !== -1) {
        state.toasts.splice(findToastIndex, 1);
      }
    },
  },
});

export const createToast =
  ({ message, variant }: { message: string; variant: ToastVariant }) =>
  (dispatch: AppDispatch) => {
    const id = uniqueId();
    dispatch(startToast({ id, message, variant }));
    setTimeout(() => dispatch(endToast({ id })), 2000);
  };

export const { startToast, endToast } = toastSlice.actions;

export default toastSlice.reducer;
