import React from 'react';

import classNames from 'classnames';
import { useInView } from 'react-intersection-observer';

import Typography from '../../../design-system/Typography/Typography';

import ReviewCarousel from './ReviewCarousel/ReviewCarousel';

import styles from './Section4.module.scss';

const Section4: React.FC = () => {
  const [section4Ref, section4InView] = useInView({
    threshold: 0.6,
    triggerOnce: true,
  });

  return (
    <div className={classNames(styles.section4, { [styles.view]: section4InView })} ref={section4Ref}>
      <Typography variant="Subtitle_1">
        <span className={styles.zero}>헤이딜러 제로</span>로 감가없이 <br />
        거래했어요!
      </Typography>
      <ReviewCarousel />
    </div>
  );
};

export default Section4;
