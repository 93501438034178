import { createToast } from 'store/toastSlice';

import { useAppDispatch } from './useAppDispatch';

export const useToast = () => {
  const dispatch = useAppDispatch();
  return {
    default: (message: string) => dispatch(createToast({ message, variant: 'default' })),
    success: (message: string) => dispatch(createToast({ message, variant: 'success' })),
    fail: (message: string) => dispatch(createToast({ message, variant: 'fail' })),
  };
};
